import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { RfzoService } from '../rfzo.service';

@Component({
  selector: 'app-storno',
  templateUrl: './storno.component.html',
  styleUrls: ['./storno.component.scss']
})
export class StornoComponent implements OnInit {

  public type: any = 1;
  public nijeCjn: any = 0;
  public MBZu: string = "07046219";
  public PIBZu: string = "100136676";

  constructor(private _service: RfzoService) { }

  ngOnInit(): void {
  }

  storno: FormGroup = new FormGroup({
    idUpisRFZO: new FormControl('', []),
  });

  public Submit() {
    if (this.storno.valid) {
      this._service.storno(this.storno).subscribe(res => {
        var json = JSON.parse(res._body);
        alert(json.response.msg);
      });
    }
  }

}
