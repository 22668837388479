import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { Http, Response, Headers } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RfzoService {

  constructor(private _http: Http) { }

  send(faktura: any): Observable<any> {

    var data = {
      "idZu": environment.MB,
      "user": environment.RFZO_USR,
      "pass": environment.RFZO_PSW,
      "type": +faktura.controls.type.value,
      "racun1": faktura.controls.racun1.value,
      "racun2": faktura.controls.racun2.value,
      "MB": faktura.controls.MB.value,
      "PIB": faktura.controls.PIB.value,
      "JBBK": faktura.controls.JBBK.value,
      "MBZu": faktura.controls.MBZu.value,
      "PIBZu": faktura.controls.PIBZu.value,
      "banka": faktura.controls.banka.value,
      "brojUgovora": faktura.controls.brojUgovora.value,
      "tekuciRacun": faktura.controls.tekuciRacun.value,
      "brojRacuna": faktura.controls.brojRacuna.value,
      "datumIzdavanja": faktura.controls.datumIzdavanja.value,
      "datumPrometa": faktura.controls.datumPrometa.value,
      "iznosFaktureBezPdv": parseFloat(faktura.controls.iznosFaktureBezPdv.value).toFixed(2),
      "PDV": parseFloat(faktura.controls.PDV.value).toFixed(2),
      "iznosFakture": parseFloat(faktura.controls.iznosFakture.value).toFixed(2),
      "valuta": faktura.controls.valuta.value,
      "kpp": faktura.controls.kpp.value,
      "pozivBroj": faktura.controls.pozivBroj.value,
      "sifraPlacanja": faktura.controls.sifraPlacanja.value,
      "nijeCjn": 0
    }

    let headers = new Headers();
    headers.append("Accept", "application/json");
    var url = 'http://fakturisanje.rtc.rs/php/send_invoice.php'
    return this._http.post(url, data, { headers: headers }).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((response: Response) => {
        console.log("err");
        return throwError('Došlo je do greške na servisu RFZO');
      }));
  }

  storno(form: any): Observable<any> {
    var data = {
      "idZu": environment.MB,
      "user": environment.RFZO_USR,
      "pass": environment.RFZO_PSW,
      "MB": environment.MB,
      "PIB": environment.PIB,
      "idUpisRFZO": form.controls.idUpisRFZO.value
    }
    let headers = new Headers();
    headers.append("Accept", "application/json");
    var url = 'http://fakturisanje.rtc.rs/php/storno_invoice.php'
    return this._http.post(url, data, { headers: headers }).pipe(
      map((response: Response) => {
        return response;
      }),
      catchError((response: Response) => {
        console.log("err");
        return throwError('Došlo je do greške na servisu RFZO');
      }));
  }

}
