// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://ws.rfzo.rs/Fin/',
  //API_URL: 'https://ws.rfzo.rs/FinTest/',
  USER: 'rtc',
  PSW: 'rtc1990',
  RFZO_USR: 'rtcdoo',
  RFZO_PSW: 'T5j83rz!',
  MB: '07518790',
  PIB: '100348281',
  RACUN: '160-69480-89',
  BANKA: 'Banca Intesa'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
