import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(private router: Router) { }  

  login: FormGroup = new FormGroup({
    korisnickoIme: new FormControl('', [Validators.required]),
    sifra: new FormControl('', [Validators.required])
  });
  hide = true;

  public submit() {
    if (this.login.value.korisnickoIme == environment.USER && this.login.value.sifra == environment.PSW) {
      this.router.navigate(['/upload']);
    } else {
      alert('Pogrešno korisničko ime / šifra')
    }
  }

}
