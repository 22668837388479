
<mat-card class="formaWrap">

  <a class="btn" routerLink="/upload">Fakturisanje</a>
  <br />

  <form class="forma" [formGroup]="storno" (ngSubmit)="Submit()">
    <mat-form-field class="input" title="">
      <mat-label>Vrednost koju je metoda vratila nakon uspešnog pozivanja servisa</mat-label>
      <input matInput formControlName="idUpisRFZO" required>
    </mat-form-field>

    <button mat-raised-button color="primary">Storniraj</button>
  </form>
</mat-card>
