<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<mat-card class="login">
  <form class="loginForm" [formGroup]="login" (ngSubmit)="submit()">
    <mat-form-field class="name">
      <mat-label>Korisničko ime</mat-label>
      <input matInput formControlName="korisnickoIme" value="" required>
    </mat-form-field>

    <mat-form-field class="pass">
      <mat-label>Šifra</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="sifra" required value="">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
    <button mat-raised-button color="primary">Uloguj se</button>
  </form>
</mat-card>
